export interface IReducerState {
	physicalId: string;
	applicationId: string;
	correctlyInstalled: boolean;
}

const initialState = {
	physicalId: "",
	applicationId: "",
	correctlyInstalled: false,
};

type PhysicalIDAction = {type: "SET_PHYSICALID"; payload: string};
type ApplicationIDAction = {type: "SET_APPLICATIONID"; payload: string};
type CorrectlyInstalledAction = {type: "SET_CORRECTLYINSTALLED"; payload: boolean};
type GoToAnchor = {type: "GO_TO_ANCHOR"};
type ResetAction = {type: "RESET_ACTION"};
type Action = PhysicalIDAction | ApplicationIDAction | CorrectlyInstalledAction | ResetAction | GoToAnchor;

export const reducer = (state: IReducerState = initialState, action: Action) => {
	switch (action.type) {
		case "SET_PHYSICALID": {
			return {
				physicalId: action.payload,
				applicationId: state.applicationId,
				correctlyInstalled: state.correctlyInstalled,
			};
		}
		case "SET_APPLICATIONID": {
			return {
				physicalId: state.physicalId,
				applicationId: action.payload,
				correctlyInstalled: state.correctlyInstalled,
			};
		}
		case "SET_CORRECTLYINSTALLED": {
			return {
				physicalId: state.physicalId,
				applicationId: state.applicationId,
				correctlyInstalled: action.payload,
			};
		}
		case "GO_TO_ANCHOR": {
			return {
				physicalId: "",
				applicationId: state.applicationId,
				correctlyInstalled: false
			}
		}
		case "RESET_ACTION": {
			return {
				physicalId: "",
				applicationId: "",
				correctlyInstalled: false,
			};
		}
		default: {
			return state;
		}
	}
};
