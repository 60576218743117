//MUI
import { Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
//React-Router
import { NavigateFunction, useNavigate } from "react-router-dom";
//Components
import ViewerComponent from "./ViewerComponent";
//Redux
import { useSelector } from "react-redux";
import { IReducerState } from "../../context/reducer";
import { store } from "../../context/store";
//React
import { useEffect, useState } from "react";
//Services
import { BuildingPart, Client } from "../../services/apiClient";
//Config
import Config from "../../Config";

interface ISpeckleState {
	modelUrl: string | null;
}

const Speckle = () => {
	const applicationId: any = useSelector<IReducerState>(
		(state: IReducerState) => state.applicationId
	);
	const [state, setState] = useState<ISpeckleState>({ modelUrl: null });
	const navigate: NavigateFunction = useNavigate();

	const baseUrl: string = Config.BaseUrl as string;
	const serverUrl: string = Config.ServerUrl as string;
	const modelId: string = Config.ModelId as string;

	const goToAnchorDetails = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		if (!applicationId) {
			return;
		}
		const client: Client = new Client(serverUrl);
		client
			.buildingPart(applicationId)
			.then((buildingPart: BuildingPart) => {
				if (buildingPart) {
					store.dispatch({
						type: "SET_PHYSICALID",
						payload: buildingPart.physicalId
							? buildingPart.physicalId
							: "",
					});
					store.dispatch({
						type: "SET_CORRECTLYINSTALLED",
						payload: buildingPart.correctlyInstalled
							? buildingPart.correctlyInstalled
							: false,
					});
				}
				navigate("../anchorDetails", { replace: true });
			});
	};

	const goToHome = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		navigate("../", { replace: true });
	};

	const resetStream = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		const client: Client = new Client(baseUrl);
		client.resetModel(modelId).then((message: string) => {
			navigate("../", { replace: true });
			store.dispatch({ type: "RESET_ACTION" });
		});
	};

	useEffect(() => {
		if (state.modelUrl) {
			return;
		}
		const client = new Client(serverUrl);
		client
			.latestModel(modelId)
			.then((url: string) => {
				setState({ modelUrl: url });
			})
			.catch((error: any) => {
				console.log(error.message);
			});
	});

	const viewerComponent: any = state.modelUrl ? (
		<ViewerComponent speckleUrl={state.modelUrl} />
	) : (
		<div />
	);

	return (
		<div className="span12">
			<nav className="navbar fixed-top navbar-expand-lg navbar-light" style={{width: "100vw", display: "inline-block"}}>
				<div className="container-fluid">
					<div>
						<h1>
							<Button className="fas fa-chevron-left pull-left" onClick={goToHome} sx={{color: "#000", marginRight: "50px"}} startIcon={<ChevronLeftIcon />}>
								Level 3
							</Button>
						</h1>
					</div>
					<div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xl-offset-9 col-lg-offset-9 col-md-offset-9 col-sm-offset-9"></div>
					<Button variant="outlined" onClick={resetStream} type="button" className="pull-right" sx={{borderRadius: "10px;", marginBottom: "1vh"}}>
						RESET
					</Button>
				</div>
			</nav>
			{viewerComponent}
			<div className="d-flex justify-content-center footer">
				<Button variant="contained" disabled={applicationId === "" ? true : false} size="large" color="error" onClick={goToAnchorDetails} sx={{width: "90vw"}}>
					Select Anchor
				</Button>
			</div>
		</div>
	);
};
export default Speckle;
