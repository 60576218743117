//React-Router
import {NavigateFunction, useNavigate} from "react-router-dom";
//MUI
import {Button} from "@mui/material";

const Home = () => {
	const navigate: NavigateFunction = useNavigate();

	const goToViewer = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		navigate("../speckleViewer", {replace: true});
	};

	return (
		<div>
			<div className="container-fluid wrapper" style={{padding: "0px", height: "90vh"}}>
				<div className="row-fluid">
					<div className="span12">
						<div style={{backgroundColor: "rgb(215, 24, 42) "}}>
							<img src="assets/icons/HiltiLLogoBig - Full.jpg" className="img-fluid" alt="Responsive image" style={{height: "100%", width: "100%"}} />
						</div>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center footer">
				<Button variant="contained" size="large" color="error" onClick={goToViewer} sx={{width: "90vw"}}>
					Start
				</Button>
			</div>
		</div>
	);
};
export default Home;
