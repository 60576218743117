//React
import React, { useEffect, useState } from "react";
//Speckle
import { Viewer } from "@speckle/viewer";
import { store } from "../../context/store";
//Configuration
import Config from "../../Config";

interface IViewerProps {
	speckleUrl: string;
}

interface IViewerState {
	viewer: any;
}

const ViewerComponent: React.FC<IViewerProps> = (props) => {
	const [state, setState] = useState<IViewerState>({ viewer: null });
	const zoomFactor: number = Number(Config.zoomFactor as string);
	let objectToZoomId: string = Config.ObjectToZoomId as string;

	useEffect(() => {
		const rendererDiv = document.getElementById("renderer");
		if (!rendererDiv || state.viewer) {
			return;
		}
		const viewer = new Viewer({container: rendererDiv, showStats: false});

		const zoomToObjectId = (id: string) => {
			let obj = viewer.sceneManager.allObjects.find(
				(o: { userData: { applicationId: string } }) =>
					o.userData.applicationId === id
			);
			if (obj) {
				viewer.interactions.zoomToObject(obj, zoomFactor, false);
			} else {
				console.warn(`No object with id of ${id} found.`);
			}
		};

		viewer.loadObject(props.speckleUrl).then(async () => {
			zoomToObjectId(objectToZoomId);
			viewer.interactions.rotateTo("front", false);

			viewer.cameraHandler.controls.dollyToCursor = true;
			viewer.needsRender = true;
		});
		
		viewer.on("select", (objects: string | any[]) => {
			if (objects.length > 0) {
				const applicationId: string = objects[0].applicationId;
				store.dispatch({
					type: "SET_APPLICATIONID",
					payload: applicationId,
				});
				if (objects[0].physicalId) {
					const physicalId: string = objects[0].physicalId;
					store.dispatch({
						type: "SET_PHYSICALID",
						payload: physicalId,
					});
				}
				if (objects[0].correctlyInstalled) {
					const correctlyInstalled: boolean =
						objects[0].correctlyInstalled;
					store.dispatch({
						type: "SET_CORRECTLYINSTALLED",
						payload: correctlyInstalled,
					});
				}
				return;
			}
			store.dispatch({ type: "SET_APPLICATIONID", payload: "" });
		});
		setState({ viewer: viewer });
	});

	return (
		<div className="viewer-debug">
			<h3 className="mt-0 m-b-10 font-bold">Viewer</h3>
			<div
				style={{ width: "100vw", height: "87vh", paddingTop: "1vh" }}
				id="renderer"
			/>
		</div>
	);
};
export default ViewerComponent;
