//MUI
import { Button, TextField } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
//React
import { useState } from "react";
//Redux
import { useSelector } from "react-redux";
import { IReducerState } from "../../context/reducer";
//React-Router
import { NavigateFunction, useNavigate } from "react-router-dom";
//Components
import QRScanner from "../qrscanner/Scanner";
//Context
import { store } from "../../context/store";
//Services
import { Client } from "../../services/apiClient";
//Config
import Config from "../../Config";

interface IAnchorAssignmentState {
	snackbar: boolean;
}

const AnchorAssignment = () => {
	const navigate: NavigateFunction = useNavigate();
	const [state, setState] = useState<IAnchorAssignmentState>({
		snackbar: false,
	});
	const applicationId: any = useSelector<IReducerState>(
		(state: IReducerState) => state.applicationId
	);
	const physicalId: any = useSelector<IReducerState>(
		(state: IReducerState) => state.physicalId
	);
	const correctlyInstalled: any = useSelector<IReducerState>(
		(state: IReducerState) => state.correctlyInstalled
	);

	const baseUrl: string = Config.BaseUrl as string;
	const serverUrl: string = Config.ServerUrl as string;
	const modelId: string = Config.ModelId as string;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({
			snackbar: state.snackbar,
		});
		store.dispatch({ type: "SET_PHYSICALID", payload: event.target.value });
		store.dispatch({
			type: "SET_CORRECTLYINSTALLED",
			payload: correctlyInstalled,
		});
	};

	const handleClose = () => {
		setState({
			snackbar: !state.snackbar,
		});
	};

	const goToScan = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		navigate("../anchorDetails", { replace: true });
	};

	const resetStream = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		const client: Client = new Client(baseUrl);
		client.resetModel(modelId).then((message: string) => {
			navigate("../", { replace: true });
			store.dispatch({ type: "RESET_ACTION" });
		});
	};

	const sendAnchorAssignment = () => {
		const client: Client = new Client(serverUrl);
		client
			.assignAnchor(applicationId, physicalId, correctlyInstalled)
			.then(() => {
				setState({ snackbar: true });
			});
	};

	return (
		<div>
			<nav className="navbar fixed-top navbar-expand-lg navbar-light" style={{width: "100vw", display: "inline-block"}}>
				<div className="container-fluid" style={{marginTop: "1vh"}}>
					<Button className="fas fa-chevron-left pull-left" onClick={goToScan} sx={{color: "#000", marginRight: "50px"}} startIcon={<ChevronLeftIcon />}>
						Scan
					</Button>
					<div className="col-xl-1 col-lg-1  col-md-1 col-xl-offset-10 col-lg-offset-10 col-md-offset-10"></div>
					<Button variant="outlined" type="button" onClick={resetStream} className="pull-right" sx={{borderRadius: "10px;"}}>
						RESET
					</Button>
				</div>
			</nav>
			<div className="col-xl-12" style={{marginTop: "80px"}}>
				<div className="col-xl-12" style={{paddingLeft: "2vw", paddingRight: "2vw"}}>
					<div className="form-group">
						<TextField fullWidth autoFocus={true} id="outlined-name" value={physicalId} label="Physical Identification" onChange={handleChange} />
					</div>
				</div>
				<div className="row">
					<div
						className="col-12 "
						style={{
							marginTop: "30px",
							paddingLeft: "3vw",
							paddingRight: "0px",
							textAlign: "left",
						}}>
						<label>Correctly installed/validated?</label>
					</div>
					<div
						className="col-12"
						style={{
							marginTop: "30px",
							paddingLeft: "3vw",
							paddingRight: "0px",
							display: "flex",
							float: "left",
						}}>
						<label className="toggle">
							<input
								type="checkbox"
								checked={correctlyInstalled}
								onClick={() => {
									store.dispatch({
										type: "SET_CORRECTLYINSTALLED",
										payload: !correctlyInstalled,
									});
								}}
							/>
							<span className="slider"></span>
							<span className="labels" data-on="YES" data-off="NO"></span>
						</label>
					</div>
				</div>
			</div>
			<Snackbar open={state.snackbar} autoHideDuration={6000} onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity="success"
					className={"justify-content-center"}
					sx={{
						position: "fixed",
						bottom: "10vh",
						left: "25vw",
						width: "50%",
					}}>
					Anchor successfully assigned!
				</Alert>
			</Snackbar>
			<div className="d-flex justify-content-center footer">
				<Button variant="contained" disabled={physicalId !== "" ? false : true} size="large" color="error" sx={{width: "90vw"}} onClick={sendAnchorAssignment}>
					Accept Code
				</Button>
			</div>
		</div>
	);
};
export default AnchorAssignment;
