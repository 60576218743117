//React
import React from "react";
import ReactDOM from "react-dom";
//Styles
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
//Components
import App from "./App";
//Packages
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
//Context
import {store} from "./context/store";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
