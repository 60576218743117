//Styles
import "./App.css";
//Components
import Home from "./components/Home";
import SpeckleViewer from "./components/viewer/Speckle";
import AnchorDetails from "./components/anchor/AnchorDetails";
import AnchorAssignment from "./components/anchor/AnchorAssignment";
//React-Router-DOM
import {Route, Routes} from "react-router-dom";

function App() {
	return (
		<div className="App">
			<Routes>
				<Route index element={<Home />} />
				<Route path="/speckleViewer" element={<SpeckleViewer />} />
				<Route path="/anchorDetails" element={<AnchorDetails />} />
				<Route path="/anchorAssignment" element={<AnchorAssignment />} />
			</Routes>
		</div>
	);
}
export default App;
