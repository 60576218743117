//MUI
import { Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
//React-Router
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IReducerState } from "../../context/reducer";
import { Client } from "../../services/apiClient";
import { store } from "../../context/store";
//Config
import Config from "../../Config";

const AnchorDetails = () => {
	const navigate: NavigateFunction = useNavigate();

	const baseUrl: string = Config.BaseUrl as string;
	const modelId: string = Config.ModelId as string;

	const physicalID: any = useSelector<IReducerState>(
		(state: IReducerState) => state.physicalId
	);

	const goToAnchorAssignment = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		navigate("../anchorAssignment", { replace: true });
	};

	const goToAnchor = (event: React.MouseEvent<HTMLElement>) => {
		store.dispatch({type: "GO_TO_ANCHOR"})
		event.preventDefault();
		navigate("../speckleViewer", { replace: true });
	};

	const resetStream = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		const client: Client = new Client(baseUrl);
		client.resetModel(modelId).then((message: string) => {
			navigate("../", { replace: true });
			store.dispatch({ type: "RESET_ACTION" });
		});
	};

	return (
		<div>
			<nav className="navbar fixed-top navbar-expand-lg navbar-light" style={{width: "100vw", display: "inline-block"}}>
				<div className="container-fluid" style={{marginTop: "1vh"}}>
					<Button className="fas fa-chevron-left pull-left" onClick={goToAnchor} sx={{color: "#000", marginRight: "50px"}} startIcon={<ChevronLeftIcon />}>
						Anchor
					</Button>
					<div className="col-xl-1 col-lg-1  col-md-1 col-xl-offset-10 col-lg-offset-10 col-md-offset-10"></div>
					<Button variant="outlined" onClick={resetStream} type="button" className="pull-right" sx={{borderRadius: "10px;"}}>
						RESET
					</Button>
				</div>
			</nav>
			<div className="row " style={{marginTop: "80px"}}>
				<div className="col-md-12">
					<div className="btn-group col-xl-12 col-md-12" role="group" aria-label="Basic example">
						<button type="button" className="btn btn-danger" style={{borderColor: "red", borderWidth: "2px"}}>
							Info
						</button>
						<button type="button" className="btn btn-light" style={{borderColor: "red", borderWidth: "2px"}} disabled>
							Documents
						</button>
					</div>
				</div>
			</div>
			<div className="card-body col-lg-12" style={{marginTop: "20px"}}>
				<h2> Anchor Details </h2>
			</div>
			<table className="table table-striped">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Item number</th>
						<th scope="col">Physical ID</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>User Name</td>
						<td>123123-fsd123-ad123sdae12-123ads2535</td>
						<td>{physicalID}</td>
					</tr>
				</tbody>
			</table>

			<div className="d-flex justify-content-center footer">
				<Button variant="contained" size="large" color="error" onClick={goToAnchorAssignment} sx={{width: "90vw"}}>
					Assign Anchor
				</Button>
			</div>
		</div>
	);
};
export default AnchorDetails;
